<template>
  <div class="container">

          <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="分享id">
          <el-input v-model="form.pid"></el-input>
        </el-form-item>
        <el-form-item label="潜在事故类型">
          <el-input v-model="form.riskpoint"></el-input>
        </el-form-item>
        <el-form-item label="主要危害">
          <el-input v-model="form.hazard"></el-input>
        </el-form-item>
        <el-form-item label="管控措施">
          <el-input v-model="form.controls"></el-input> </el-form-item
        ><el-form-item label="工程措施">
          <el-input v-model="form.projectrola"></el-input>
        </el-form-item>
        <el-form-item label="个人措施">
          <el-input v-model="form.personage"></el-input>
        </el-form-item>
        <el-form-item label="管理措施">
          <el-input v-model="form.manager"></el-input>
        </el-form-item>

        <el-form-item label="风险程度">
          <el-input v-model="form.riskrank"></el-input>
        </el-form-item>
        <el-form-item label="L">
          <el-input v-model="form.L"></el-input>
        </el-form-item>
        <el-form-item label="E">
          <el-input v-model="form.E"></el-input> </el-form-item
        ><el-form-item label="C">
          <el-input v-model="form.C"></el-input>
        </el-form-item>

        <el-button type="primary" @click="submit">提交</el-button>
      </el-form>

    <el-dialog title="添加风险" :visible.sync="addDialogVisible" width="30%">
      <el-form ref="addFormData" :model="addFormData" label-width="120px">
        <el-form-item label="所属工程">
          <el-input v-model="addFormData.name"></el-input>
        </el-form-item>
        <el-form-item label="作业活动名称">
          <el-input v-model="addFormData.name"></el-input>
        </el-form-item>
        <el-form-item label="风险描述">
          <el-input v-model="addFormData.name"></el-input>
        </el-form-item>
        <el-form-item label="潜在事故类型">
          <el-input v-model="addFormData.name"></el-input>
        </el-form-item>

        <el-form-item label="具体区域">
          <el-input v-model="addFormData.name"></el-input>
        </el-form-item>
        <el-form-item label="有效时间">
          <el-input v-model="addFormData.name"></el-input>
        </el-form-item>
        <el-form-item label="责任人">
          <el-input v-model="addFormData.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addDialogVisible: false,
      tableData: [],
      addFormData: {},
         form: {
        pid: "",
        riskpoint: "",
        hazard: "",
        controls: "",
        projectrola: "",
        personage: "",
        manager: "",
        riskrank: "",
        L: "",
        E: "",
        C: "",
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    addRisk() {
      this.addDialogVisible = true;
    },
    submit(){
      console.log('4');
        this.$axios.post("http://192.168.0.106:97/api/jksb/cccccccc/",this.form).then((res)=>{
         console.log(res);
         if(res.data.data == "ok") {
           this.$message({
             type:"success",
             message:"添加成功"
           })
         }
       })
    }
  },
};
</script>
<style lang="less" scoped>
.main {
  margin-top: 20px;
  padding: 20px;
}
</style>